import { State, Action, StateContext, Store, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  FetchSelectedMenuItem,
  SetPortion,
  SetSubModifierItem,
  RemoveModifierItem,
  SetModifierItem,
  SubmitModifierItem,
  UpdateItemCount,
  SetItemDetails,
  AddItemToCart,
  UpdateCartItem,
  RemoveSubModifierItem,
  UpdateCustomItemCount,
  AddCartCombo,
  AddItemToCombo,
  ValiateMenuItem,
} from '../actions';
import { MenuItemService } from '../services';
import { tap, count } from 'rxjs/operators';
import { ComboState } from './combo.state';
import { group } from '@angular/animations';

export class MenuItemStateModel {
  fetchedMenuItem: any;
  selectedMenuItem: any;
  submitted: boolean;
  count: number;
  itemDetails: any;
  cartDetails: any;
}

@State<MenuItemStateModel>({
  name: 'menuItem',
  defaults: {
    fetchedMenuItem: null,
    selectedMenuItem: null,
    submitted: false,
    count: 1,
    itemDetails: null,
    cartDetails: null,
  },
})
@Injectable()
export class MenuItemState {
  constructor(private _menuItem: MenuItemService, private store: Store) {}

  @Selector()
  static getSelectedMenuItem(state: MenuItemStateModel) {
    return state.selectedMenuItem;
  }

  @Selector()
  static getGeneratedPortion(state: MenuItemStateModel) {
    return state.selectedMenuItem.portion;
  }

  @Selector()
  static getGeneratedModifiers(state: MenuItemStateModel) {
    return state.selectedMenuItem.modifiers;
  }

  @Selector()
  static getGeneratedCount(state: MenuItemStateModel) {
    return state.count;
  }

  @Selector()
  static getItemDetails(state: MenuItemStateModel) {
    return state.itemDetails;
  }

  @Selector()
  static getSubmitted(state: MenuItemStateModel) {
    return state.submitted;
  }

  @Selector()
  static getCartDetails(state: MenuItemStateModel) {
    return state.cartDetails;
  }

  @Selector()
  static getMenuItemPrice(state: MenuItemStateModel) {
    const { selectedMenuItem, count } = state;
    const { price, modifiers, portions, organizationId , discountedPrice } = selectedMenuItem;
    let basePrice = price || 0;

    const foundSelectedPortion =
      portions.find((portion) => portion.selected) || portions[0];
    
    console.log(selectedMenuItem);
    let discount = selectedMenuItem.discount;
    let pricetoAdd = 0;

    if (foundSelectedPortion) {
      pricetoAdd = typeof foundSelectedPortion.discountedPrice === 'number' ? foundSelectedPortion.discountedPrice : foundSelectedPortion.price;
      discount = foundSelectedPortion.discount;
    } else if (typeof discountedPrice === 'number' && discountedPrice >= 0) {
      pricetoAdd = discountedPrice;
    }
    
    if (organizationId.other.isRelative) {
      basePrice += pricetoAdd;
    } else if(pricetoAdd >= 0 && ( foundSelectedPortion != undefined || discountedPrice != undefined )) {
      basePrice = pricetoAdd;
    }

    let totalModifierPrice = modifiers.reduce((modifiersTotal, modifier) => {
      if (modifier && (modifier.selected || modifier.count)) {
        const { modifierItems } = modifier;
        if (modifierItems && modifierItems.length > 0) {
          const modifierTotal = modifierItems.reduce(
            (modifierItemTotal, modifierItem) => {
              if (modifierItem && (modifierItem.selected || modifierItem.count)) {
                const { items, costDifference } = modifierItem;

                if (items && items.length > 0) {
                  const itemsPrice = items.reduce((itemsTotal, item) => {
                    if (item.selected || item.count) return itemsTotal + item.costDifference * (item.count ? item.count : 1);
                    else return itemsTotal;
                  }, 0);

                  return modifierItemTotal + itemsPrice;
                } else return modifierItemTotal + costDifference *( modifierItem.count ? modifierItem.count : 1);
              } else {
                return modifierItemTotal;
              }
            },
            0
          );
          return modifiersTotal + modifierTotal;
        }
      } else {
        return modifiersTotal;
      }
    }, 0);
    
    if(discount && totalModifierPrice > 0) {
      if (discount.type == '$') {
        if(basePrice === 0) {
          let discounted = 0;
          if(foundSelectedPortion) {
            discounted = foundSelectedPortion.price + totalModifierPrice - parseFloat(discount.value);
          } else {
            discounted = price + totalModifierPrice - parseFloat(discount.value);
          }
          basePrice = discounted > 0 ? discounted : 0;

        } else {
          basePrice += totalModifierPrice;
        }
        // totalModifierPrice = (totalModifierPrice - parseFloat(discount.value));
      } else 
      if (discount.type == '%') {
        totalModifierPrice = Math.round((totalModifierPrice * (1 - (discount.value / 100))) * 100) / 100;
        basePrice += totalModifierPrice;
      }
    } else {
      basePrice += totalModifierPrice;
    }

    if (count) basePrice = basePrice * count;
    return basePrice;
  }
  @Selector()
  static getMenuItemPoints(state: MenuItemStateModel) {
    const { selectedMenuItem, count } = state;
    const { price, modifiers, portions, organizationId , discountedPrice, employeeLoyaltyPoints} = selectedMenuItem;
    let basePrice = price || 0;
    let basePoints = employeeLoyaltyPoints || 0;

    const foundSelectedPortion =
      portions.find((portion) => portion.selected) || portions[0];
    
    console.log(selectedMenuItem);
    let discount = selectedMenuItem.discount;
    let pointstoAdd = 0;

    if (foundSelectedPortion) {
      pointstoAdd = typeof foundSelectedPortion.discountedPrice === 'number' ? foundSelectedPortion.discountedPrice : foundSelectedPortion.employeeLoyaltyPoints;
      discount = foundSelectedPortion.discount;
    } else if (typeof discountedPrice === 'number' && discountedPrice >= 0) {
      pointstoAdd = discountedPrice;
    }
    
    if (organizationId.other.isRelative) {
      basePoints += pointstoAdd;
    } else if(pointstoAdd >= 0 && ( foundSelectedPortion != undefined || discountedPrice != undefined )) {
      basePoints = pointstoAdd;
    }


    let totalModifierPoints = modifiers.reduce((modifiersTotal, modifier) => {
      if (modifier && (modifier.selected || modifier.count)) {
        const { modifierItems } = modifier;
        if (modifierItems && modifierItems.length > 0) {
          const modifierTotal = modifierItems.reduce(
            (modifierItemTotal, modifierItem) => {
              if (modifierItem && (modifierItem.selected || modifierItem.count)) {
                const { items, employeeLoyaltyPoints } = modifierItem;

                if (items && items.length > 0) {
                  const itemsPoints = items.reduce((itemsTotal, item) => {
                    if (item.selected || item.count) return itemsTotal + item.employeeLoyaltyPoints * (item.count ? item.count : 1);
                    else return itemsTotal;
                  }, 0);

                  return modifierItemTotal + itemsPoints;
                } else return modifierItemTotal + employeeLoyaltyPoints *( modifierItem.count ? modifierItem.count : 1);
              } else {
                return modifierItemTotal;
              }
            },
            0
          );
          return modifiersTotal + modifierTotal;
        }
      } else {
        return modifiersTotal;
      }
    }, 0);
    
    // if(discount && totalModifierPrice > 0) {
    //   if (discount.type == '$') {
    //     if(basePrice === 0) {
    //       let discounted = 0;
    //       if(foundSelectedPortion) {
    //         discounted = foundSelectedPortion.price + totalModifierPrice - parseFloat(discount.value);
    //       } else {
    //         discounted = price + totalModifierPrice - parseFloat(discount.value);
    //       }
    //       basePrice = discounted > 0 ? discounted : 0;

    //     } else {
    //       basePrice += totalModifierPrice;
    //     }
    //     // totalModifierPrice = (totalModifierPrice - parseFloat(discount.value));
    //   } else 
    //   if (discount.type == '%') {
    //     totalModifierPrice = Math.round((totalModifierPrice * (1 - (discount.value / 100))) * 100) / 100;
    //     basePrice += totalModifierPrice;
    //   }
    // } else {
    //   basePrice += totalModifierPrice;
    // }
    basePoints += totalModifierPoints;
    if (count) basePoints = basePoints * count;
    return basePoints;
  }
  @Selector()
  static getGeneratedFreeTextModifiers(state: MenuItemStateModel) {
    return state.selectedMenuItem.freeTextModifiers;
  }
  @Action(FetchSelectedMenuItem)
  fetchMainMenu(
    { patchState, getState }: StateContext<MenuItemStateModel>,
    { payload, isCombo, menuItemIdInGroup }: FetchSelectedMenuItem
  ) {
    const { cartDetails } = getState();
    patchState({
      selectedMenuItem: null,
      count: 1,
    });
    return this._menuItem.fetchMenuItemDetails(payload).pipe(
      tap((response) => {
        let menuItemDetails = response;
        const fetchedMenuItem = clone(menuItemDetails);
        if (
          menuItemDetails &&
          menuItemDetails.portions &&
          menuItemDetails.portions.length > 0
        ) {
          let findDefault;

          if(isCombo){
            const comboItem = this.store.selectSnapshot(ComboState.getComboItem(menuItemIdInGroup));
            if(comboItem?.portions?.length && menuItemDetails?.portions.length){
              menuItemDetails.portions = menuItemDetails.portions.filter( selectedItemPortion => {
                return !!comboItem.portions.find( comboItemPortion => comboItemPortion._id === selectedItemPortion._id );
              });

            }
          }
          menuItemDetails.portions.forEach((portion) => {
            portion.selected = false;
            if (portion.isDefault === true) {
              findDefault = portion;
            }
          });


          let selectedPortionId;
          if (cartDetails && cartDetails.portion) {
            selectedPortionId = cartDetails.portion._id;
          } else if (findDefault) {
            findDefault.selected = true;
            selectedPortionId = findDefault._id;
          } else {
            menuItemDetails.portions[0].selected = true;
            selectedPortionId = menuItemDetails.portions[0]._id;
          }

          const defaultPortion = menuItemDetails.portions.find( portion => portion.selected)
          if(!defaultPortion)
            menuItemDetails.portions[0].selected = true;

          menuItemDetails.modifiers = filterPortion(
            menuItemDetails,
            selectedPortionId
          );
          if(menuItemDetails.freeTextModifiers && menuItemDetails.freeTextModifiers.length){
            menuItemDetails.freeTextModifiers = filterPortionForFreeTextModifier(
              menuItemDetails,
              selectedPortionId
            );
          }


        }
        if(menuItemDetails.modifiers && menuItemDetails.modifiers.length){
          menuItemDetails.modifiers = formatModifiers(menuItemDetails.modifiers);
        }
        if (cartDetails)
          menuItemDetails = selectCartItems(menuItemDetails, cartDetails);
          else{
            let copyMenuItemDetails = menuItemDetails;
            menuItemDetails = setDefaultSelected(copyMenuItemDetails);
          }
        const validateDetails = checkValidations(menuItemDetails);
        const patchData = {
          selectedMenuItem: validateDetails,
          fetchedMenuItem: fetchedMenuItem,
          submitted: false,
        };

        if (cartDetails) patchData['count'] = (cartDetails as any).count;
        if (menuItemDetails) patchState(patchData);
      })
    );
  }



  @Action(SetPortion)
  setGeneratedPortion(
    { getState, patchState }: StateContext<MenuItemStateModel>,
    { payload }: SetPortion
  ) {
    const { selectedMenuItem, fetchedMenuItem } = getState();
    selectedMenuItem.portions = selectedMenuItem.portions.map((portion) => {
      portion.selected = portion._id === payload;
      return portion;
    });
    const tempCopy = clone(fetchedMenuItem);
    Object.freeze(tempCopy);
    let filteredModifiers = filterPortion(tempCopy, payload);
    if(filteredModifiers && filteredModifiers.length){
      filteredModifiers = formatModifiers(filteredModifiers);
    }
    let filteredFreeTextModifiers = filterPortionForFreeTextModifier(tempCopy, payload);
      selectedMenuItem.freeTextModifiers = filteredFreeTextModifiers;


    selectedMenuItem.modifiers = filteredModifiers;
    let copySelectedMenuItem;
    copySelectedMenuItem = setDefaultSelected(selectedMenuItem);
    patchState({
      selectedMenuItem: checkValidations(copySelectedMenuItem),
    });
  }


  @Action(SetModifierItem)
  setGeneratedSubModifier(
    { getState, patchState }: StateContext<MenuItemStateModel>,
    { id, payload }: SetModifierItem
  ) {
    const { selectedMenuItem } = getState();
    const foundModifier = selectedMenuItem.modifiers.find(
      (modifier) => modifier._id === id
    );
    if (foundModifier && !foundModifier.isCounter) {
      const { modifierItems, maxLimit } = foundModifier;

      if (modifierItems && modifierItems.length > 0) {
        const foundModifierItem = foundModifier.modifierItems.find(
          (modifierItem) => modifierItem._id === payload.id
        );

        if (foundModifierItem) {
          const selectedItems = modifierItems.filter(
            (modifierItem) => modifierItem.selected
          );

          if (
            (maxLimit > 0 && maxLimit > selectedItems.length) ||
            maxLimit === 0
          ) {
            foundModifierItem.selected = true;
            foundModifier.selected = true;
          } else if (maxLimit === 1) {
            foundModifier.modifierItems = foundModifier.modifierItems.map(
              (modifierItem) => {
                modifierItem.selected = false;
                modifierItem.items = modifierItem.items.map((item) => {
                  item.selected = false;
                  return item;
                });
                return modifierItem;
              }
            );
            foundModifierItem.selected = true;
            foundModifier.selected = true;
          } else {
            foundModifierItem.selected = false;
            const selectedSubItems = modifierItems.filter(
              (modifierItem) => modifierItem.selected
            );
            if (selectedSubItems.length === 0) foundModifier.selected = false;
          }
        }
      }
    }
    if (foundModifier && foundModifier.isCounter) {
      const { modifierItems, maxLimit } = foundModifier;

      if (modifierItems && modifierItems.length > 0) {
        const foundModifierItem = foundModifier.modifierItems.find(
          (modifierItem) => modifierItem._id === payload.id
        );

        if (foundModifierItem) {
          let count = 0;
          modifierItems.forEach(
            (modifierItem) =>{ count += (modifierItem.count ? 1: 0)}
          );

          if (
            (maxLimit > 0 && maxLimit >= count) ||
            maxLimit === 0
          ) {
            if(!foundModifierItem.selected){
              foundModifier.selected = true;
              foundModifier.count +=  1;
            }
            foundModifierItem.selected = true;
            foundModifierItem.count = payload.count;

          } else if (maxLimit === 1) {

            if(!foundModifierItem.selected){
              foundModifier.selected = true;
              foundModifier.count +=  1;
            }
            foundModifierItem.selected = true;
            foundModifierItem.count = payload.count;
            } else {
            foundModifierItem.selected = false;
            foundModifierItem.count = 0;
            const selectedSubItems = modifierItems.filter(
              (modifierItem) => modifierItem.selected
            );
            if (selectedSubItems.length === 0) {foundModifier.selected = false; foundModifier.count = 0;}
          }
        }
      }
    }

    patchState({
      selectedMenuItem: checkValidations(selectedMenuItem),
    });
  }

  @Action(RemoveModifierItem)
  removeGeneratedSubModifier(
    { getState, patchState }: StateContext<MenuItemStateModel>,
    { id, payload }: RemoveModifierItem
  ) {
    const { selectedMenuItem } = getState();
    const foundModifier = selectedMenuItem.modifiers.find(
      (modifier) => modifier._id === id
    );
    if (foundModifier && !foundModifier.isCounter) {
      const { modifierItems } = foundModifier;
      if (modifierItems && modifierItems.length > 0) {
        const foundModifierItem = foundModifier.modifierItems.find(
          (modifierItem) => modifierItem._id === payload.id
        );
        if (foundModifierItem) {
          foundModifierItem.selected = false;
          const selectedModifierItems = foundModifier.modifierItems.filter(
            (modifierItem) => modifierItem.selected === true
          );
          if (selectedModifierItems.length === 0)
            foundModifier.selected = false;
        }
      }
    }
    if (foundModifier && foundModifier.isCounter) {
      const { modifierItems } = foundModifier;
      if (modifierItems && modifierItems.length > 0) {
        const foundModifierItem = foundModifier.modifierItems.find(
          (modifierItem) => modifierItem._id === payload.id
        );
        if (foundModifierItem) {
          foundModifierItem.selected = (payload.count) ?  true : false;
          foundModifierItem.count = payload.count;
          let selectedModifierItemsCount = 0;
           foundModifier.modifierItems.forEach(
            (modifierItem) =>{
              selectedModifierItemsCount += (modifierItem.count ? 1 : 0)}
          );
          if (selectedModifierItemsCount=== 0)
            {
              foundModifier.selected = false;
              foundModifier.count = 0;
            }
            else{
              foundModifier.selected = true;
              foundModifier.count = selectedModifierItemsCount;
            }
        }
      }
    }
    patchState({
      selectedMenuItem: checkValidations(selectedMenuItem),
    });
  }

  @Action(SetSubModifierItem)
  setGeneratedSubModifierItem(
    { getState, patchState }: StateContext<MenuItemStateModel>,
    { id, subId, payload }: SetSubModifierItem
  ) {
    const { selectedMenuItem } = getState();
    const foundModifier = selectedMenuItem.modifiers.find(
      (modifier) => modifier._id === id
    );
    if (foundModifier  && !foundModifier.isCounter) {
      let { modifierItems } = foundModifier;
      const modifierMaxLimit = foundModifier.maxLimit;
      const selectedModifierItems = modifierItems.filter(
        (modifierItem) => modifierItem.selected
      );
      if (modifierMaxLimit === 0 || selectedModifierItems.length <= modifierMaxLimit) {
        if (modifierItems && modifierItems.length > 0) {
          const foundModifierItem = foundModifier.modifierItems.find(
            (modifierItem) => modifierItem._id === subId
          );

          if (foundModifierItem) {
            let { items, maxLimit } = foundModifierItem;
            const foundSubModifierItem = items.find(
              (subModifier) => subModifier._id === payload.id
            );
            const selectedItems = items.filter(
              (subModifier) => subModifier.selected
            );

            if (foundSubModifierItem) {
              if (maxLimit === 1) {
                // modifierItems = modifierItems.map((modifierItem) => {
                //   modifierItem.selected = false;
                const modifierPresent = selectedModifierItems.find(modifierItem => modifierItem._id === foundModifierItem._id)
                // if(modifierMaxLimit && selectedModifierItems.length === modifierMaxLimit && !modifierPresent )
                //   return;
                if (modifierMaxLimit === 1) {
                  modifierItems = modifierItems.map((modifierItem) => {
                    if(modifierItem._id != foundModifierItem._id){
                      modifierItem.selected = false;
                      modifierItem.items = modifierItem.items.map((item) => {
                        item.selected = false;
                        return item;
                      });
                    }
                    else{ 
                      if(selectedModifierItems && selectedItems.length){
                        modifierItem.items = modifierItem.items.map((item) => {
                          if(item._id != foundSubModifierItem._id){
                            item.selected = false;
                           
                          }
                            return item;
                        })
                      }

                    }
                    return modifierItem;
                  });
                } else {
                  items = items.map((item) => {
                    item.selected = false;
                    return item;
                  });
                }
                foundSubModifierItem.selected = true;
                foundModifierItem.selected = true;
                foundModifier.selected = true;
              } else if (
                (maxLimit && maxLimit >= selectedItems.length) ||
                maxLimit === 0
              ) {
                if(maxLimit && maxLimit === selectedItems.length)
                  return;
                if (maxLimit === 1) {
                  items = items.map((item) => {
                    item.selected = false;
                    return item;
                  });
                }
                // const modifierPresent = selectedModifierItems.find(modifierItem => modifierItem._id === foundModifierItem._id)
                // if(modifierMaxLimit && selectedModifierItems.length === modifierMaxLimit && !modifierPresent )
                //   return;
                if (modifierMaxLimit === 1)
                  modifierItems = modifierItems.map((modifierItem) => {
                    if(modifierItem._id != foundModifierItem._id && modifierItem?.items)
                      modifierItem.items.forEach( item => {
                        item.selected = false;
                      })
                    modifierItem.selected = false;
                    return modifierItem;
                  });
                foundSubModifierItem.selected = true;
                foundModifierItem.selected = true;
                foundModifier.selected = true;
              } else {
                foundSubModifierItem.selected = false;
                foundModifierItem.selected = false;
                foundModifier.selected = false;
              }
            }
          }
        }
      } else {
        const foundModifierItem = foundModifier.modifierItems.find(
          (modifierItem) => modifierItem._id === subId
        );
        let { items } = foundModifierItem;
        const foundSubModifierItem = items.find(
          (subModifier) => subModifier._id === payload.id
        );
        foundModifierItem.selected = false;
        foundSubModifierItem.selected = false;
      }
    }
    if (foundModifier && foundModifier.isCounter) {
      let { modifierItems } = foundModifier;
      const modifierMaxLimit = foundModifier.maxLimit;
      let selectedModifierItemscount = 0;
        modifierItems.forEach(
        (modifierItem) =>{
        selectedModifierItemscount += modifierItem.count ? 1 : 0 ;
        }
      );
      if (modifierMaxLimit === 0 || selectedModifierItemscount <= modifierMaxLimit) {
        if (modifierItems && modifierItems.length > 0) {
          const foundModifierItem = foundModifier.modifierItems.find(
            (modifierItem) => modifierItem._id === subId
          );

          if (foundModifierItem) {
            let { items, maxLimit } = foundModifierItem;
            const foundSubModifierItem = items.find(
              (subModifier) => subModifier._id === payload.id
            );
            let selectedItemsCount = 0;
            items.filter(
              (subModifier) => {selectedItemsCount += (subModifier.count ? 1 : 0)}
            );

            if (foundSubModifierItem) {
              if (maxLimit === 1) {
                foundSubModifierItem.selected = true;
                foundSubModifierItem.count = payload.count;
                if(!foundModifierItem.selected){
                  foundModifierItem.selected = true;
                  foundModifierItem.count = 1;
                  foundModifier.selected = true;
                  foundModifier.count +=  1;
                }
              } else if (
                (maxLimit && maxLimit >= selectedItemsCount) ||
                maxLimit === 0
              ) {

                foundSubModifierItem.selected = true;
                foundSubModifierItem.count = payload.count;
                if(!foundModifierItem.selected){
                  foundModifierItem.selected = true;
                  foundModifierItem.count = 1;
                  foundModifier.selected = true;
                  foundModifier.count +=  1;
                }

              }
            }
          }
        }
      } else {
        const foundModifierItem = foundModifier.modifierItems.find(
          (modifierItem) => modifierItem._id === subId
        );
        let { items } = foundModifierItem;
        const foundSubModifierItem = items.find(
          (subModifier) => subModifier._id === payload.id
        );
        foundModifierItem.selected = payload.count ? true :  false;
        foundModifierItem.count = selectedModifierItemscount + payload.count;
        foundSubModifierItem.selected =payload.count ? true :  false;
        foundSubModifierItem.count = payload.count;
      }
    }
    patchState({
      selectedMenuItem: checkValidations(selectedMenuItem),
    });
  }

  @Action(RemoveSubModifierItem)
  removeGeneratedSubModifierItem(
    { getState, patchState }: StateContext<MenuItemStateModel>,
    { id, subId, payload }: RemoveSubModifierItem
  ) {
    const { selectedMenuItem } = getState();
    const foundModifier = selectedMenuItem.modifiers.find(
      (modifier) => modifier._id === id
    );
    if (foundModifier && !foundModifier.isCounter) {
      const { modifierItems } = foundModifier;
      if (modifierItems && modifierItems.length > 0) {
        const foundModifierItem = modifierItems.find(
          (modifierItem) => modifierItem._id === subId
        );
        if (foundModifierItem) {
          const { items } = foundModifierItem;
          const foundSubModifierItem = items.find(
            (item) => item._id === payload.id
          );
          if (foundSubModifierItem) {
            foundSubModifierItem.selected = false;
            const selectedSubmodifierItems = items.filter(
              (item) => item.selected
            );
            if (selectedSubmodifierItems.length === 0) {
              foundModifierItem.selected = false;
              const selectedModifierItems = foundModifier.modifierItems.filter(
                (modifierItem) => modifierItem.selected === true
              );
              if (selectedModifierItems.length === 0)
                foundModifier.selected = false;
            }
          }
        }
      }
    }
    if (foundModifier && foundModifier.isCounter) {
      const { modifierItems } = foundModifier;
      if (modifierItems && modifierItems.length > 0) {
        const foundModifierItem = modifierItems.find(
          (modifierItem) => modifierItem._id === subId
        );
        if (foundModifierItem) {
          const { items } = foundModifierItem;
          const foundSubModifierItem = items.find(
            (item) => item._id === payload.id
          );
          if (foundSubModifierItem) {
            foundSubModifierItem.selected = payload.count ? true:  false;
            foundSubModifierItem.count = payload.count;
            let selectedSubmodifierItemsCount = 0;
            items.forEach(
              (item) => { selectedSubmodifierItemsCount+= (item.count ? 1: 0) }
            );
            if (selectedSubmodifierItemsCount === 0) {
              foundModifierItem.selected = false;
              foundModifierItem.count = 0;
              let selectedModifierItemsCount = 0;
              foundModifier.modifierItems.forEach(
                (modifierItem) => {selectedModifierItemsCount += (modifierItem.count ? 1 : 0)}
              );
              if (selectedModifierItemsCount === 0)
                {foundModifier.selected = false;
                foundModifier.count = 0;
                }
                else{
                  foundModifier.count = selectedModifierItemsCount;
                }
            }
          }
        }
      }
    }
    patchState({
      selectedMenuItem: checkValidations(selectedMenuItem),
    });
  }

  @Action(SubmitModifierItem)
  submitMenuItem({ getState, patchState }: StateContext<MenuItemStateModel>,{ payload,menuItemIdInGroup }: SubmitModifierItem) {
    const { selectedMenuItem, count, cartDetails } = getState();
    console.log(payload);
    if (checkValidations(selectedMenuItem).valid === true) {
      const foundPortion = selectedMenuItem.portions.find(
        (portion) => portion.selected
      );
      let cartModifiers = selectedMenuItem.modifiers.map((modifier) => {
        if (modifier && (modifier.count || modifier.selected)) {
          const formattedModifier = {
            cartModifier: modifier._id,
            cartModifierItems: [],
          };
          if (modifier.modifierItems.length > 0) {
            formattedModifier.cartModifierItems = modifier.modifierItems.map(
              (modifierItem) => {
                if (modifierItem && (modifierItem.count || modifierItem.selected)) {
                  const formattedModifierItem = {
                    cartModifierItem: modifierItem._id,
                    type: modifierItem.type,
                    count :modifierItem.count,
                    cartModifierSubItems: [],
                  };
                  if (modifierItem.items.length > 0) {
                    formattedModifierItem.cartModifierSubItems = modifierItem.items.map(
                      (item) => {
                        if (item && (item.count || item.selected))
                          return {
                            cartModifierSubItem: item._id,
                            count :item.count,
                          };
                      }
                    );
                    formattedModifierItem.cartModifierSubItems = formattedModifierItem.cartModifierSubItems.filter(
                      (item) => item != null
                    );
                  }

                  return formattedModifierItem;
                }
              }
            );
          }
          formattedModifier.cartModifierItems = formattedModifier.cartModifierItems.filter(
            (cartModifierItem) => cartModifierItem != null
          );
          return formattedModifier;
        }
      });
      cartModifiers = cartModifiers.filter(
        (cartModifier) => cartModifier != null
      );


      const formattedMenuItem: any = {
        count: count,
        menuItem: selectedMenuItem._id,
        portion: null,
        cartModifiers: [],
        cartFreeTextModifiers : [],
      };
      if (foundPortion) formattedMenuItem.portion = foundPortion._id;
      if (cartModifiers) formattedMenuItem.cartModifiers = cartModifiers;


      if(selectedMenuItem.freeTextModifiers && selectedMenuItem.freeTextModifiers.length){
        let freeTextModifiers = selectedMenuItem.freeTextModifiers.map((modifier) => {
          if (modifier && modifier.value ) {
            const formattedModifier = {
              cartModifier: modifier._id,
              value: modifier.value,
            };
            return formattedModifier;
          }
        });
        freeTextModifiers = freeTextModifiers.filter(mod=> mod != undefined);
        if (freeTextModifiers) formattedMenuItem.cartFreeTextModifiers = freeTextModifiers;
      }


      const formattedMenuItems = [formattedMenuItem];

      if(payload){
        return this.store.dispatch(new AddItemToCombo({ ...formattedMenuItem, _id : menuItemIdInGroup, selectedMenuItem },payload));
      }
      if (cartDetails) {
        const { cartDetails } = getState() as any;
        formattedMenuItem._id = cartDetails._id;
        this.store.dispatch(new UpdateCartItem(formattedMenuItem));
      } else this.store.dispatch(new AddItemToCart(formattedMenuItems));
    }

    patchState({
      submitted: true,
    });
  }

  @Action(UpdateItemCount)
  updateCount(
    { getState, patchState }: StateContext<MenuItemStateModel>,
    { payload }: UpdateItemCount
  ) {
    let { count } = getState();
    count += payload;
    if (count > 0)
      patchState({
        count: count,
      });
  }

  @Action(UpdateCustomItemCount)
  UpdateCustomItemCount(
    { getState, patchState }: StateContext<MenuItemStateModel>,
    { payload }: UpdateItemCount
  ) {
    let { count } = getState();
    count = payload;
    if (count > 0)
      patchState({
        count: count,
      });
  }

  @Action(SetItemDetails)
  setItemDetails(
    { patchState }: StateContext<MenuItemStateModel>,
    { payload }: SetItemDetails
  ) {
    let { cartDetails, ...itemDetails } = payload;
    patchState({
      itemDetails: itemDetails,
      cartDetails: cartDetails,
    });
  }

  @Action(ValiateMenuItem)
  valiateMenuItem(
    { getState, patchState }: StateContext<MenuItemStateModel>,
    { payload }: ValiateMenuItem
  ) {
    const { selectedMenuItem } = getState();
    patchState({
      selectedMenuItem: checkValidations(selectedMenuItem),
    });
  }
}



const clone = (data) => {
  if(data)
  return JSON.parse(JSON.stringify(data));
};

const checkValidations = (menuItemDetails) => {
  const menuItem = menuItemDetails;
  // Check if all the modifiers are valid
  const modifiersValid = menuItem.modifiers.reduce(
    (modifierValid, modifier) => {
      const { minLimit, maxLimit, modifierItems } = modifier;
      let modifierValidFlag = true;
      const selectedModifierItems = modifierItems.filter(
        (modifierItem) => modifierItem.count || modifierItem.selected
      );
      let modifierItemsValid = true;  //we start with the indivual items are valid
      let modifierRequiredCheck = true; //we start with the grouping item is also valid.
      //apparently based on the logic above you can have a count OR the count might be missing or 0 but the item IS selected, so add 1 in those cases to.
      let groupItemsQuantity = 0;
      for (const subItem of selectedModifierItems) {
        groupItemsQuantity += (subItem.count > 0 ? subItem.count: 1)
      }

      if (modifier.isForced || groupItemsQuantity > 0) {
        if (modifier.isForced) {
          const minimumModifierItems = minLimit || 1;
          if (groupItemsQuantity < minimumModifierItems)
            modifierRequiredCheck = false;  //INVALID
        }

        //here we check if each item is valid with it's individual min and max limits.
        modifierItemsValid = modifierItems.reduce(
          (modifierItemValid, modifierItem) => {
            let modifierItemValidFlag = true;
            let modifierItemRequiredCheck = true;
            const { items, minLimit, maxLimit } = modifierItem;
            const selectedSubItems = items.filter((item) => item.count || item.selected );
            if (modifierItem.isForced || selectedSubItems.length > 0) {
              if (modifierItem.isForced) {
                const minimumSubItems = minLimit || 1;
                if (selectedSubItems.length < minimumSubItems)
                  modifierItemRequiredCheck = false;
              }
              //has max limit and item amount is less than min = error, item amount is more than max = error
              if (
                maxLimit &&
                (minLimit > selectedSubItems.length ||
                  maxLimit < selectedSubItems.length)
              )
                modifierItemValidFlag = false;
            }

            modifierItem.valid =
              modifierItemValidFlag && modifierItemRequiredCheck;
            return modifierItemValid && modifierItemValidFlag;
          },
          true
        );

        //has maxlimit and groupedItemAmount is less than min = error or groupedItemAmount is more than max.
        if (
          maxLimit &&
          (minLimit > groupItemsQuantity ||
            maxLimit < groupItemsQuantity)
        ) {
          modifierValidFlag = false;
        }
      } else
        modifierItems.forEach((modifierItem) => {
          modifierItem.valid = true;
        });

      modifierValidFlag =
        modifierValidFlag && modifierItemsValid && modifierRequiredCheck;
      modifier.valid = modifierValidFlag;
      return modifierValid && modifierValidFlag;
    },
    true
  );


  if(menuItem.freeTextModifiers && menuItem.freeTextModifiers.length){
    const freeTextModifierValid = menuItem.freeTextModifiers.reduce(
      (freeTextModifierValid, modifier) => {
        if (modifier.isForced)
        {
          if(modifier?.value?.trim().length > 0){
            modifier.valid = true;
            return freeTextModifierValid && true;
          }
          else{
            modifier.valid = false;
            return freeTextModifierValid && false;
          }

        }
        else{
          modifier.valid = true;
          return freeTextModifierValid && true;

        }
      },
      true
    );

    menuItem.valid = modifiersValid && freeTextModifierValid;
    return menuItem;
  }
  else{
    menuItem.valid = modifiersValid;
    return menuItem;
  }

};
const setDefaultSelected = (copyMenuItemDetails) => {
  copyMenuItemDetails.modifiers.forEach((modifieritem) => {
    modifieritem.modifierItems.forEach((item)=>{
     item.items.forEach((subItems)=>{
       if(subItems.isDefaultSelected){
        item["selected"]=true;
        subItems["selected"]=true;
        modifieritem["selected"]=true;
       }
     });
      if(item.isDefaultSelected)
      {
        item["selected"]=true;
        modifieritem["selected"]=true;
      }
    });
  });
  return copyMenuItemDetails;
};
const filterPortion = ({ modifiers }, portionId) => {
  const copy = clone(modifiers);
  const filteredPortions = copy.filter((modifier) => {
    const { portions } = modifier;
    if (portions && portions.length > 0) {
      const foundPorton = portions.find((portion) => portion === portionId);
      return foundPorton != undefined;
    } else return true;
  });
  return filteredPortions;
};

const selectCartItems = (menuItemDetails, cartDetails) => {
  const { modifiers, portions , freeTextModifiers} = menuItemDetails;
  if (cartDetails && cartDetails.portion) {
    const { portion } = cartDetails;
    portions.forEach((eachPortion) => {
      eachPortion.selected = eachPortion._id === portion._id;
    });
  }
  if (
    cartDetails &&
    cartDetails.cartModifiers &&
    cartDetails.cartModifiers.length > 0
  ) {
    const { cartModifiers } = cartDetails;
    cartModifiers.forEach((eachCartModifier) => {
      const { cartModifier, cartModifierItems } = eachCartModifier;
      if (cartModifier) {
        const foundMenuModifier = modifiers.find(
          (eachModifier) => eachModifier._id === cartModifier._id
        );
        if (foundMenuModifier) {
          foundMenuModifier.selected = true;
          foundMenuModifier.count = 0;
          if (cartModifierItems && cartModifierItems.length > 0) {
            cartModifierItems.forEach((eachCartModifierItem) => {
              const { cartModifierSubItems } = eachCartModifierItem;
              const foundModifierItem = foundMenuModifier.modifierItems.find(
                (eachModifierItem) =>
                  eachModifierItem._id ===
                  eachCartModifierItem.cartModifierItem._id
              );
              foundModifierItem.selected = true;
              if(eachCartModifierItem.count){
                foundModifierItem.count = eachCartModifierItem.count;
                foundMenuModifier.count +=1;
              }

              let count = 0;
              if (cartModifierSubItems && cartModifierSubItems.length > 0) {
                cartModifierSubItems.forEach((eachCartItem) => {
                  const foundSubItem = foundModifierItem.items.find(
                    (eachItem) =>
                      eachItem._id === eachCartItem.cartModifierSubItem._id
                  );
                  foundSubItem.selected = true;
                  if(eachCartItem.count){
                    foundSubItem.count = eachCartItem.count;
                    count += eachCartItem.count;
                  }
                });
              }
              if(count && count > 0){
                foundMenuModifier.count +=1;
              }
            });
          }
        }
      }
    });
  }
  if (
    cartDetails &&
    cartDetails.cartFreeTextModifiers &&
    cartDetails.cartFreeTextModifiers.length > 0
  ) {
    const { cartFreeTextModifiers } = cartDetails;
    cartFreeTextModifiers.forEach((eachCartModifier) => {
      const { cartModifier } = eachCartModifier;
      if (cartModifier) {
        const foundMenuModifier = freeTextModifiers.find(
          (eachModifier) => eachModifier._id === cartModifier._id
        );
        if (foundMenuModifier) {
          if(eachCartModifier.value){
            foundMenuModifier.value = eachCartModifier.value;
            foundMenuModifier.selected = true;
          }

        }
      }
    });
  }

  return menuItemDetails;
};
const formatModifiers = (modifiers) => {
  let vm=this;
  modifiers.forEach(modifier=>{
   if(modifier.isCounter)
   { modifier.count = 0;
    if(modifier.modifierItems && modifier.modifierItems.length){
      modifier.modifierItems.forEach(modifierItem =>{
        if(modifierItem.type == 'item'){
         // modifierItem.count = modifierItem.minLimit ? modifierItem.minLimit : 0;
         modifierItem.count = modifierItem.minLimit ? modifierItem.minLimit :(modifierItem.minLimit==0 && modifierItem.isDefaultSelected)?1:0;
          if( modifierItem.count> 0){
            modifier.count = modifier.count > 0 ? parseInt(modifier.count) + 1: 0;
            modifier.selected = true;
            modifierItem.selected = true;
          }
        }
        else if(modifierItem.type =='modifier'){
          if(modifierItem && modifierItem.items && modifierItem.items.length){
            let count =0;
            modifierItem.items.forEach(subModifierItem=>{
              //subModifierItem.count = subModifierItem.minLimit ? subModifierItem.minLimit : 0;
              subModifierItem.count = subModifierItem.minLimit ? subModifierItem.minLimit :(subModifierItem.minLimit==0 && subModifierItem.isDefaultSelected)?1:0;
              if(subModifierItem.count){
                subModifierItem.selected = true;
                count++;
              }


            });
            if(count > 0){
              modifierItem.count = 1;
              modifierItem.selected = true;
              modifier.selected = true;
              modifier.count = modifier.count > 0 ? parseInt(modifier.count) + 1 : 1;
            }
            else {
              modifierItem.count = 0;
            }
          }
        }
      });
    }
  }
  });
  return modifiers;
};
function getCount(){

}

const filterPortionForFreeTextModifier = ({ freeTextModifiers }, portionId) => {
  const copy = clone(freeTextModifiers);
  if(copy) {
  const filteredPortions = copy.filter((modifier) => {
    const { portions } = modifier;
    if (portions && portions.length > 0) {
      const foundPorton = portions.find((portion) => portion === portionId);
      return foundPorton != undefined;
    } else return true;
  });
  return filteredPortions;
} else return {}
};


